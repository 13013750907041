import React, { useState, useEffect } from 'react';
import ServiceSelect from './ServiceSelect';
import ServiceBlock from './ServiceBlock';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { nanoid } from 'nanoid'
import apiClient from '../../helper/apiClient';
// import { da } from 'react-day-picker/locale';


export default function AdminServiceEditor({ type, userId, identifier }) {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [fieldType, setFieldType] = useState('');
    
    const [isSaved, setIsSaved] = useState(false);

    useEffect(() => {
      if (isSaved) {
          toast.success('Sikeres mentés.')
          setIsSaved(false);
      }
  }, [isSaved]);

    // const [services, setServices] = useState([{ id: Date.now(), label: '', hour: 0, minute: 0 }]);
    // const [originalServices, setOriginalServices] = useState([{ id: Date.now(), label: '', hour: 0, minute: 0 }]);

    const [services, setServices] = useState([]);
    const [originalServices, setOriginalServices] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (type === "service") {
            setFieldType('Szolgáltatásaid');
        }
        getUserServices(type);
    }, [type]);

    const getUserServices = async (type) => {
      setLoading(true);
  
      const uuidFromCookie = Cookies.get('uuid');
  
      const fieldData = {
          type,
          userId: uuidFromCookie,
          identifier
      };
  
      try {
        const response = await apiClient.post(`/api/services/select`, fieldData);
  
        if (response.status === 404) {
          // handleLogout();
          setLoading(false);
          return;
        }

        const data = response.data;
        if (data) {
          setServices(data);
          setOriginalServices(data);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };


    const handleLogout = () => {
        Cookies.remove('uuid');
        Cookies.remove('firstname');
        window.location.reload();
    };

    const getServiceIds = (services) => services.map((service) => service.id);

    const updateUserServices = async (services) => {
      try {
          const serviceIds = getServiceIds(services);
          const originalServiceIds = getServiceIds(originalServices);
          const deletableIds = originalServiceIds.filter(id => !serviceIds.includes(id));
  
          const response = await apiClient.post(`/api/services/save`, { services, userId, deletableIds });
  
          if (response.status === 200) {
            setOriginalServices(services);
            setIsSaved(true);
          }
      } catch (error) {
          console.log('error', error)
          if (error.code === 'ECONNABORTED') {
              toast.error('A szerver nem válaszolt időben.');
          } else if (error.response && error.response.data && error.response.data.code === 'ER_DUP_ENTRY') {
              toast.error('A szolgáltatás már létezik.');
          } else {
              console.error('Error:', error);
              toast.error('Hiba történt.');
          }
      }
  };


  const addService = () => {
    const newServices = [...services, { id: nanoid(), label: '', description: '',  headcount: 0, image:'', hour: 0, minute: 0, date_type: '', repetitive_days: null, start_hour: 0, start_minute: 0 }];
    setServices(newServices);
    updateUserServices(newServices);
  };

  const removeService = (id) => {
    const newServices = services.filter(service => service.id !== id);
    setServices(newServices);
    updateUserServices(newServices)
    //TO DO: remove service's image
  };

  const updateService = (id, updatedService) => {
    const newServices = services.map(service => service.id === id ? updatedService : service);
    setServices(newServices);
    updateUserServices(newServices)
  };

  if(loading) {
    return (
      <div className='flex justify-center min-h-50-screen'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    ) 

  }

  return (
    // <div className="collapse collapse-arrow bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
    // <div className="services collapse bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
    //   <input type="checkbox" defaultChecked disabled style={{cursor: 'default'}}/>
    //   <div className="collapse-title text-xl font-medium bg-t-beige">{fieldType}</div>
    //   <div className="collapse-content">
    <>
    <div className="text-3xl mb-12 font-medium leading-none tracking-tight text-t-dark">Szolgáltatásaim</div>
    <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-4'>
        {services && services.map(service => (
          <ServiceBlock
            key={service?.id}
            toast={toast}
            userId={userId}
            identifier={identifier}
            service={service}
            removeService={removeService}
            updateService={updateService}
          />
        ))}
        {/* <div className='flex mt-4 w-full items-center justify-end'> */}
        <div
          onClick={addService} 
          className='card cursor-pointer hover:bg-t-light card-compact bg-t-light/50 shadow-xl text-center justify-center text-7xl font-normal'>
            +
        </div>
        
    {/*   </div>
    </div> */}
    </div>
      {/* <div className='text-right'>
        <button
          className='btn bg-t-green btn-outline mt-4'
          onClick={updateUserServices}
          disabled={loading || (originalServices === services)}
        >
          Mentés
        </button>
      </div> */}
    </>
  );
}