import React from 'react'
import AdminMenu from './AdminMenu'

const NavBar = ({ userFirstName, setPage}) => {
  return (
    <div className="navbar bg-base-100 mb-10 shadow">
        <div className="navbar-start">
            <div className="text-xl flex items-center">
                <img src='../timely_logo.svg' width="40px" height="40px"/>
                Timely
            </div>
        </div>
        <div className="navbar-center hidden min-[420px]:flex">
            <p className="text-base">Szia{userFirstName && `, ${userFirstName}`}!</p>
        </div>
        <div className="navbar-end">
            <AdminMenu setPage={setPage}/>
        </div>
    </div>
  )
}

export default NavBar