import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
// import NotFound from './components/NotFound';
import UserPage from './pages/UserPage';
import Admin from './pages/Admin';
import Register from './pages/Register';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from './components/Logo';
import Footer from './components/Footer';
import SuccessRegister from './pages/SuccessRegister';

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        closeButton={false}
        transition={Bounce}
      />
      <Logo/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success" element={<SuccessRegister />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="/:identifier" element={<UserPage />} />
        <Route path="/:identifier/admin" element={<Admin />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
