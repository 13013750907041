import React, {useEffect, useState} from 'react';
import '../App.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';

import { DayPicker } from "react-day-picker";
import { hu } from "react-day-picker/locale";
import "react-day-picker/style.css";
import apiClient from '../helper/apiClient';

function UserPage() {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const { identifier } = useParams();
  const [template, setTemplate] = useState(null);
  const [fields, setFields] = useState(null);

  // const [selected, setSelected] = useState(toMariaDBTimestamp(new Date()));
  const [loading, setLoading] = useState(true);

  const divClass = "container sm:rounded-lg rounded-none my-4 md:w-4/6 sm:w-5/6 bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10"

  const [selectedDate, setSelectedDate] = useState(toMariaDBTimestamp(new Date()));

  const modifiers = {};
  if (selectedDate) {
    modifiers.selected = selectedDate;
  }

  useEffect(() => {

    // const getFields = () => {
    //   fetch(`${apiBaseUrl}api/fields?identifier=${identifier}`)
    //   // .then(response => response.json())
    //   .then(response => {
    //     if (response.status === 404) {
    //         // toast.error('Ez az oldal nem található.');
    //       navigate('/register?id=' + identifier);
    //       return;
    //     }
    //     return response.json();
    //   })
    //   .then(data => {
    //     setFields(data);
    //     // setTemplate(data?.template);
    //   })
    //   .catch(error => console.error('Error fetching user fields:', error));
    // }


    const getFields = async () => {
      try {
          const response = await apiClient.get(`/api/fields`, {
              params: { identifier }, // Use params to construct the query string
          });
  
          // Assuming you want to handle 404 status here
          if (response.status === 404) {
              navigate('/register?id=' + identifier);
              return;
          }
  
          // Set the fields data from the response
          setFields(response.data);
          // setTemplate(response.data?.template); // Uncomment if needed
      } catch (error) {
          // Handle error response specifically
          if (error.response) {
              console.error('Error fetching user fields:', error.response);
              // Optionally handle specific status codes if needed
          } else {
              console.error('Error fetching user fields:', error);
          }
      }
  };

    
    const getTemplate = () => {
      fetch(`${apiBaseUrl}api/template?identifier=${identifier}`)
      // .then(response => response.json())
      .then(response => {
        if (response.status === 404) {
          toast.error('Ez az oldal nem található.');
          // navigate('/register?identifier=' + identifier);
          return;
        }
        return response.json();
      })
      .then(data => {
        // console.log('Template data:', data);
        setTemplate(data);
        if (document && document?.body && data?.background_color) {
          document.body.style.backgroundColor = data.background_color;
        }
        //   {
          //     "background_color": "#FFFFFF",
          //     "dark_color": "#121212",
          //     "light_color": "#222222",
          //     "logo_img": "template_logo.png",
          //     "primary_color": "#FF5733",
          //     "secondary_color": "#33C3FF"
          // }
        })
        .catch(error => console.error('Error fetching user template:', error));
      }
      
      getFields()
      // getTemplate()
      setLoading(false);

  }, [identifier]);

  const handleSelect = (newSelected) => {
    if(newSelected) {
      setSelectedDate(toMariaDBTimestamp(newSelected));
      toast.info(`Ezt a dátumot választottad: ${toMariaDBTimestamp(newSelected)}`)
    } else {
      toast.info(`Ezt a dátumot választottad: ${selectedDate}`)
    }
  }

  function toMariaDBTimestamp(date) {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0'); // A hónapokat nulláról indexelik
    const day = String(date?.getDate()).padStart(2, '0');
    const hours = String(date?.getHours()).padStart(2, '0');
    const minutes = String(date?.getMinutes()).padStart(2, '0');
    const seconds = String(date?.getSeconds()).padStart(2, '0');
  
    const daysOfWeek = ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"];
    const dayOfWeek = daysOfWeek[date?.getDay()];
  
    // console.log(`A nap: ${dayOfWeek}`);
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}, ${dayOfWeek}`;
  }

  useEffect(() => {
    // Function to toggle the div visibility based on rdp-selected class
    const handleClick = (event) => {
      const tdElement = event.currentTarget;
      const divElement = tdElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
      console.log('divElement', divElement)

      // Remove existing 'rdp-selected' class from all <td> elements
      document.querySelectorAll('td').forEach(td => td.classList.remove('rdp-selected'));

      // Add the 'rdp-selected' class to the clicked <td>
      tdElement.classList.add('rdp-selected');




      // ha van rdp-selected setIsTimePicker(true)
      // {isTimePicker && <div> </div>}
      // <DayPicker.....




      // Remove existing new-div from the DOM
      const existingDiv = document.querySelector('.new-div');
      if (existingDiv) {
        existingDiv.remove();
      }

      // Insert new <div> after the button in the clicked <td>
      const buttonElement = tdElement.querySelector('button');
      if (buttonElement) {
        const newDiv = document.createElement('div');
        newDiv.textContent = 'New Div Content'; // Customize content
        newDiv.className = 'new-div'; // Assign a class for easy targeting
        buttonElement.insertAdjacentElement('afterend', newDiv);
      }
    };

    // Attach event listeners to all <td> elements
    const tdElements = document.querySelectorAll('td');
    tdElements.forEach((td) => {
      td.addEventListener('click', handleClick);
    });

    // Cleanup event listeners on component unmount
    return () => {
      tdElements.forEach((td) => {
        td.removeEventListener('click', handleClick);
      });
    };
  }, [document.querySelector('.rdp-root')]);

  // useEffect(() => {
  //   // Function to add the <div> after the <button> when rdp-selected class appears
  //   const addDivAfterButton = (tdElement) => {
  //     const buttonElement = tdElement.querySelector('button');
      
  //     if (buttonElement && !tdElement.querySelector('.new-div')) { // Prevent adding multiple divs
  //       const newDiv = document.createElement('div');
  //       newDiv.textContent = 'New Div Content'; // Customize as needed
  //       newDiv.className = 'new-div'; // Optional: Add a class to the new div for easier management
  //       buttonElement.insertAdjacentElement('afterend', newDiv);
  //     }
  //   };

  //   // Callback function for the MutationObserver
  //   const observerCallback = (mutations) => {
  //     mutations.forEach((mutation) => {
  //       if (mutation.type === 'attributes' && mutation.target.classList.contains('rdp-selected')) {
  //         addDivAfterButton(mutation.target);
  //       }
  //     });
  //   };

  //   // Set up the MutationObserver to watch for attribute changes on <td> elements
  //   const observer = new MutationObserver(observerCallback);
  //   const tdElements = document.querySelectorAll('td');
    
  //   tdElements.forEach((td) => {
  //     observer.observe(td, {
  //       attributes: true, // Observe attribute changes
  //       attributeFilter: ['class'], // Only observe changes to the 'class' attribute
  //     });
  //   });

  //   // Clean up observer on component unmount
  //   return () => observer.disconnect();
  // }, [document]);
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='user-page'>
      {(fields?.image || fields?.title) &&
        <div className={`${divClass}`}>
          {fields?.image && <div className={`${fields?.title && 'mb-4'} my-0 mx-auto rounded-full w-36 h-36 bg-cover bg-center`} style={{backgroundImage: `url(${apiBaseUrl+fields?.image})`}}/>}
          {fields?.title && <h1 className='text-center'>{fields?.title}</h1>}
        </div>
      }
      {fields?.introduction &&
        <div className={`${divClass} ql text-lg`}>
          {fields?.introduction && <div dangerouslySetInnerHTML={{ __html: fields?.introduction }} />}
        </div>
      }
      {(fields?.email || fields?.phone) && 
        <div className={`${divClass} text-lg`}>
          <h1 className='text-left mb-8'>Elérhetőségeim</h1>
          {fields?.email &&
          <div className='flex'>
            <svg className='mt-1 mr-1' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
            <div dangerouslySetInnerHTML={{ __html: fields?.email }} />
          </div>
          }
          {fields?.phone &&
          <div className='flex'>
            <svg className='mt-1 mr-1' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
            <div dangerouslySetInnerHTML={{ __html: fields?.phone }} />
          </div>
          }
        </div>
      }
      <div className={`${divClass} flex justify-center`}>
        <DayPicker
          mode="single"
          required
          ISOWeek
          locale={hu}
          modifiers={modifiers}
          onDayClick={(day, modifiers) => {
            if (modifiers.selected) {
              setSelectedDate(undefined);
            } else {
              setSelectedDate(day);
            }
          }}
          disabled={[
            { dayOfWeek: [0, 6] }, // Disable Sundays and Saturdays
            { before: new Date() } // Disable past dates
          ]}
          selected={selectedDate}
          onSelect={handleSelect} />
      </div>
    </div>
  )
}

export default UserPage;
