import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // To manage cookies
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from '../../helper/apiClient';
import { quillCustomFormats, quillCustomModules } from '../../helper/quillOptions';

function AdminFieldEditor({ type, userId, identifier }) {

  const [value, setValue] = useState('');
  const [originalValue, setOriginalValue] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if(type === "title") {
      setFieldType('Cím')
    } else if(type === "introduction") {
      setFieldType('Bemutatkozás')
    } else if(type === "phone") {
      setFieldType('Telefon')
    } else if(type === "email") {
      setFieldType('E-mail')
    }
    getUserFields(type);
  }, [type]);

  const getUserFields = async (type) => {
    setLoading(true);

    const uuidFromCookie = Cookies.get('uuid');

    const fieldData = {
        type,
        userId: uuidFromCookie,
        identifier
    };

    try {
      const response = await apiClient.post(`/api/fields/select`, fieldData);

      if (response.status === 404) {
          // handleLogout();
          return;
      }

      const data = response.data;
      if (data) {
          setValue(data[type]);
          setOriginalValue(data[type]);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
};


  const handleLogout = () => {
    Cookies.remove('uuid');
    Cookies.remove('firstname');
    window.location.reload();
  };


  const updateUserField = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (type === 'email') {
        if (value && !emailPattern.test(value)) {
            setError(true);
            toast.error('Hibás email formátum.');
            return;
        }
    }

    const fieldData = {
        type,
        userId,
        identifier,
        value
    };

    try {
      const response = await apiClient.put(`/api/fields/update`, fieldData);

      if (response.status === 500) {
          handleLogout();
          return;
      }

      toast.success('Sikeres mentés.');
      setOriginalValue(value);
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };


  return (
    <>
      <div className="collapse collapse-arrow bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
        <input type="checkbox" defaultChecked/>
            <div className="collapse-title text-xl font-medium bg-t-beige">{fieldType}</div>
        <div className="collapse-content">
          {type === 'title' && <input className='focus:outline-none w-full mt-8 p-2 border border-t-ccc bg-t-light' type="text" value={value} onChange={(e) => setValue(e.target.value)} />}
          {type === 'phone' &&
          <>
          <input className='focus:outline-none w-full mt-8 p-2 border border-t-ccc bg-t-light'
            type="text"
            value={value}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Allow only one "+" at the beginning and numbers afterwards
              if (/^(?:\+)?[0-9]*$/.test(inputValue)) {
                setValue(inputValue);
              }
            }} />
          <div className="label absolute">
            <span className="label-text-alt">Példa: +36301234567</span>
          </div>
          </>}
          
          {type === 'introduction' && <ReactQuill className='mt-8 bg-t-light' modules={quillCustomModules} formats={quillCustomFormats} theme="snow" value={value} onChange={setValue} />}
          {type === 'email' && (
            <input
              className={`focus:outline-none w-full mt-8 p-2 border ${error ? 'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500' : 'border-t-ccc bg-t-light'}`}
              type="email"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setError(false);
              }}
            />
          )}
          <div className='text-right'>
            <button className='btn bg-t-green btn-outline mt-4' onClick={updateUserField} disabled={loading || (originalValue === value)}>Mentés</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminFieldEditor;
