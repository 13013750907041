import React, { useState } from 'react';
import '../App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie'; // To manage cookies
import apiClient from '../helper/apiClient';
import { useNavigate } from 'react-router-dom';

function AdminLogin({ identifier }) {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [inputErrors, setInputErrors] = useState(
        {
            email: false,
            password: false
        }
    );

    const [loading, setLoading] = useState(false);

    const labelClass = "input input-bordered rounded-xl border-2 flex items-center gap-2 border-t-dark"
    const divClass = "container my-4 lg:w-3/6 md:w-4/6 sm:w-5/6 bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10 rounded-lg"

    const isError = async () => {
        let hasError = false;

        if (!email) {
            if(!hasError) {
                toast.error('Meg kell adnod az e-mail címed.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                email: true,
            }));
            hasError = true;
        }

        if(!password) {
            if(!hasError) {
                toast.error('Meg kell adnod a jelszavadat.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                password: true,
            }));
            hasError = true;
        }

        return hasError;
    };
    

    const handleLogin = async (e) => {
        e.preventDefault();
        setInputErrors({
            email: false,
            password: false,
        });
        setLoading(true);
    
        if (await isError()) {
            scrollToTop();
            setLoading(false);
            return;
        }
    
        const userData = {
            email,
            password,
            identifier
        };
    
        try {
            const response = await apiClient.post(`/api/login`, userData);
    
            // If login is successful
            if (response.data && response.data.id && response.data.firstname) {
                Cookies.set('firstname', response.data.firstname, { expires: 1 });
                Cookies.set('uuid', response.data.id, { expires: 1 });
                navigate(`?p=account`, { replace: true });
                window.location.reload();
            } else {
                // console.error('Login failed, no user data found.');
                setLoading(false);
            }
        } catch (error) {
            // Check for 401 error specifically
            if (error.response && error.response.status === 401) {
                toast.error('Hibás e-mail vagy jelszó.');
            } else if (error.code === 'ECONNABORTED') {
                toast.error('A szerver nem válaszolt időben.');
            } else {
                // console.error('Error:', error);
            }
            setLoading(false);
        }
    };

    const scrollToTop = () => {
        if(window !== undefined) {
            window.scrollTo({
            top: 0,
            behavior: 'smooth',
            });
        }
    };
  
    return (
    <div className='admin-login'>
        <div className={`${divClass}`}>
            <h1 className="text-center text-t-dark">{identifier.charAt(0).toUpperCase() + identifier.slice(1)} bejelentkezés</h1>
        </div>
        <div className={`${divClass}`}>
            <form onSubmit={handleLogin} className="form-control gap-4">
                <label className={`${labelClass} ${inputErrors.email ? 'border-red-500' : ''}`}>
                    E-mail cím
                    <input type="email" className="grow"
                        placeholder="hello@timely.hu"
                        value={email} 
                        onChange={e => setEmail(e.target.value)} 
                    />
                </label>
                <label className={`${labelClass} ${inputErrors.password ? 'border-red-500' : ''}`}>
                    Jelszó
                    <input type="password" className="grow"
                        placeholder="********"
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                {loading ?
                    <button className="btn bg-t-green">
                        <span className="loading loading-spinner"></span>
                    </button>
                :  
                    <button type="submit" className="btn bg-t-green hover:bg-t-pink text-lg text-t-dark">Bejelentkezés</button>
                }
            </form>
        </div>
    </div>
    );
}

export default AdminLogin;
