import React, {useEffect, useState} from 'react';
import '../App.css';
import { useLocation, useNavigate } from 'react-router-dom';

function SuccessRegister() {

  const [identifier, setIdentifier] = useState('');
  const divClass = "container my-4 w-5/6 px-10 py-10"
  const { search } = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    const params = new URLSearchParams(search);
    const id = params.get('id');
    console.log('id', id);
    if (id) {
        setIdentifier(id);
    }
}, [search]);
  

  return (
    <div className='home-page'>
      <div className="navbar mb-10 sticky">
          <div className="navbar-start">
              <div className="logo cursor-pointer lg:text-3xl lg:absolute relative flex flex-row items-end justify-center text-t-dark text-5xl font-bold" onClick={() => navigate('/')}>
                  <img className='w-20 h-20' src='../timely_logo.svg' />
                  Timely
              </div>
          </div>
          <div className="navbar-center hidden lg:flex">
          </div>
          <div className="navbar-end">
              <a className="btn bg-t-green hover:bg-t-pink border-0 text-lg fixed cursor-pointer" onClick={() => navigate('/register')}>Regisztráció</a>
          </div>
      </div>
      <h1 className="mt-8 mb-4 text-center text-4xl font-extrabold leading-none tracking-tight text-t-dark md:text-5xl lg:text-6xl">Készítsd el <span className="underline underline-offset-3 decoration-8 decoration-t-light">saját időpontfoglaló</span> rendszeredet</h1>
      <h2 className="text-3xl font-normal text-t-dark lg:text-3xl">Regisztrálj, és kezdd el foglalásaidat egyszerűen kezelni!</h2>
      
      <div className={`${divClass}`}>
        <p className="text-2xl font-normal text-t-dark">Szeretnéd egyszerűen és profin intézni az időpontfoglalásokat? Regisztrálj és saját foglalórendszert kapsz, amely minden igényedet kielégíti! Kövesd nyomon a foglalásokat, kommunikálj ügyfeleiddel.</p>
      </div>

      <h1 className="mt-8 mb-4 text-center text-3xl font-extrabold leading-none tracking-tight text-t-dark md:text-3xl lg:text-4xl">30 napig <span className="underline underline-offset-3 decoration-8 decoration-t-light">ingyenesen</span> kipróbálhatod!</h1>

      <div className={`${divClass}`}>
        {/* <p className="text-2xl font-normal text-t-dark">Szeretnéd egyszerűen és profin intézni az időpontfoglalásokat? Regisztrálj nálunk, és az előfizetéssel saját foglalórendszert kapsz, amely minden igényedet kielégíti! Kövesd nyomon a foglalásokat, kommunikálj ügyfeleiddel, és küldj emlékeztetőket pillanatok alatt.</p> */}
        <p className="text-2xl font-normal text-t-dark">Csatlakozz hozzánk és legyen saját, megbízható időpontfoglaló rendszered, amellyel még hatékonyabbá teheted a vállalkozásodat!</p>
      </div>

    </div>
  )
}

export default SuccessRegister;
