export const convertMinutesToTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60); // Get the hours
    const minutes = totalMinutes % 60;           // Get the remaining minutes
    return { hours, minutes };
  };

export const convertTimeToMinutes = (hours, minutes) => {
    const hoursToMinutes = parseInt(hours) * 60; // Convert hours to minutes
    const time = hoursToMinutes + parseInt(minutes) // Sum the minutes
    return time;
};