import React, { useState, useEffect } from 'react';

export default function RangeInput({ range, selectedDate, removeRange, updateRange }) {
    
    const [startHour, setStartHour] = useState(range.start_hour);
    const [startMinute, setStartMinute] = useState(range.start_minute);
    const [endHour, setEndHour] = useState(range.end_hour);
    const [endMinute, setEndMinute] = useState(range.end_minute);

    useEffect(() => {
        updateRange(range.id, { ...range, work_date: selectedDate, start_hour: startHour, start_minute: startMinute, end_hour: endHour, end_minute: endMinute });
    }, [startHour, startMinute, endHour, endMinute]);

    // Helper function to handle input changes
    const handleDigitInput = (e, setter, max) => {
        const value = e.target.value.replace(/\D/, ''); // Remove non-digit characters
        if (value.length <= 2 && value <= max) { // Allow max 2 digits and within range
            setter(Number(value));
        }
    };
    
    return (
        <div className="flex w-full items-center">
            <div className="flex w-full justify-evenly p-2 mb-4 border border-t-ccc bg-white">
                <div>
                    <label className="block mb-2 text-sm font-medium text-t-dark">Kezdete:</label>
                    <div className="join rounded-none relative flex items-center max-w-[8rem] border border-t-ccc">
                        <input
                            // value={startHour.toString().padStart(2, '0')}
                            value={startHour || ''}
                            onChange={(e) => handleDigitInput(e, setStartHour, 23)}
                            // length="2"
                            min="0"
                            max="23" type="text" data-input-counter
                            className="text-right focus:outline-none w-full p-2 bg-t-light"
                            placeholder="00" required
                            onInput={(e) => e.target.value = e.target.value.slice(0, 2)} // Limit to 2 digits
                        />
                        <span>:</span>
                        <input
                            // value={startMinute.toString().padStart(2, '0')}
                            value={startMinute || ''}
                            onChange={(e) => handleDigitInput(e, setStartMinute, 59)}
                            // length="2"
                            min="0"
                            max="59" type="text" data-input-counter
                            className="focus:outline-none w-full p-2 bg-t-light"
                            placeholder="00" required
                            onInput={(e) => e.target.value = e.target.value.slice(0, 2)} // Limit to 2 digits
                        />
                    </div>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-t-dark">Vége:</label>
                    <div className="join rounded-none relative flex items-center max-w-[8rem] border border-t-ccc">
                        <input
                            // value={endHour.toString().padStart(2, '0')}
                            value={endHour || ''}
                            onChange={(e) => handleDigitInput(e, setEndHour, 23)}
                            length="2"
                            min="0"
                            max="23" type="text" data-input-counter
                            className="text-right focus:outline-none w-full p-2 bg-t-light"
                            placeholder="00" required
                            onInput={(e) => e.target.value = e.target.value.slice(0, 2)} // Limit to 2 digits
                        />
                        <span>:</span>
                        <input
                            value={endMinute || ''}
                            // value={endMinute.toString().padStart(2, '0')}
                            onChange={(e) => handleDigitInput(e, setEndMinute, 59)}
                            length="2"
                            min="0"
                            max="59" type="text" data-input-counter
                            className="focus:outline-none w-full p-2 bg-t-light"
                            placeholder="00" required
                            onInput={(e) => e.target.value = e.target.value.slice(0, 2)} // Limit to 2 digits
                        />
                    </div>
                </div>
            </div>
            <div
                className='h-9 min-h-0 btn bg-t-pink btn-outline hover:text-t-light text-t-dark ml-2 align-middle text-5xl p-2 leading-11 font-normal'
                onClick={() => removeRange(range.id)}
            >
                -
            </div>
        </div>
      );
}
