import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // To manage cookies
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminProfileEditor({ userId, identifier }) {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [firstname, setFirstname] = useState('');
  const [originalFirstname, setOriginalFirstname] = useState('');

  const [lastname, setLastname] = useState('');
  const [originalLastname, setOriginalLastname] = useState('');

  const [newsletter, setNewsletter] = useState(false);
  const [originalNewsletter, setOriginalNewsletter] = useState(false);

  const [loading, setLoading] = useState(true);

  const [inputErrors, setInputErrors] = useState(
    {
        lastname: false,
        firstname: false
    }
  );

  const labelClass = "focus:outline-none mt-4 font-medium p-2 border bg-t-light w-full flex items-center gap-2"

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {

    const fieldData = {
      userId,
      identifier
    };

    fetch(`${apiBaseUrl}api/profile/select`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      },
      body: JSON.stringify(fieldData),
    })
    .then(response => {
      if (response.status === 404) {
        // handleLogout();
      }
      return response.json();
    })
    .then(data => {
      if (data) {
        setFirstname(data?.firstname);
        setOriginalFirstname(data?.firstname);

        setLastname(data?.lastname)
        setOriginalLastname(data?.lastname)
        
        setNewsletter(data?.newsletter)
        setOriginalNewsletter(data?.newsletter)
      }
      setLoading(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  const isError = async () => {
    let hasError = false;

    if (!lastname) {
        if(!hasError) {
            toast.error('Meg kell adnod a vezetéknevedet.');
        }
        setInputErrors(prevState => ({
            ...prevState,
            lastname: true,
        }));
        hasError = true;
    }

    if (!firstname) {
        if(!hasError) {
            toast.error('Meg kell adnod a keresztnevedet.');
        }
        setInputErrors(prevState => ({
            ...prevState,
            firstname: true,
        }));
        hasError = true;
    }

    return hasError;
};

  const handleLogout = () => {
    Cookies.remove('uuid');
    Cookies.remove('firstname');
    window.location.reload();
  };

  const updateNewsletter = async () => {

    const newsletterData = {
      userId,
      identifier,
      newsletter
    };

    fetch(`${apiBaseUrl}api/newsletter/update`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      },
      body: JSON.stringify(newsletterData),
    })
    .then(response => {
      if (response.status === 500) {
        setLoading(false);
        // handleLogout();
      }
      return response.json();
    })
    .then(data => {
      toast.success('Sikeres mentés.');
      setOriginalNewsletter(newsletter);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error updating newsletter:', error);
      setLoading(false);
    });
  }

  const updateProfile = async () => {

    setInputErrors({
      lastName: false,
      firstName: false
    });

    setLoading(true);

    if(await isError()) {
        scrollToTop();
        setLoading(false);
        return;
    }

    const profileData = {
      userId,
      identifier,
      lastname,
      firstname
    };

    fetch(`${apiBaseUrl}api/profile/update`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
      },
      body: JSON.stringify(profileData),
    })
    .then(response => {
      if (response.status === 500) {
        setLoading(false);
      // handleLogout();
      }
      return response.json();
    })
    .then(data => {
      toast.success('Sikeres mentés.');
      setOriginalFirstname(firstname);
      setOriginalLastname(lastname);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error updating field:', error);
      setLoading(false);
    });
  }

const scrollToTop = () => {
    if(window !== undefined) {
        window.scrollTo({
        top: 0,
        behavior: 'smooth', // For smooth scrolling
        });
    }
};


  return (
    <>
      <div className="collapse bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
        <input type="checkbox" defaultChecked disabled style={{ cursor: 'default'}}/>
        <div className="collapse-title text-xl font-medium bg-t-beige">Személyes adatok</div>
        <div className="collapse-content">
          <label className={`${labelClass} ${inputErrors.lastname ? 'border-red-500' : 'border-t-ccc'} mt-8`}>
            Vezetéknév
            {inputErrors.lastname && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
            <input type="text"
              className="grow focus:outline-none font-normal"
              placeholder="Timely"
              value={lastname}
              onChange={e => setLastname(e.target.value)}
            />
          </label>

          <label className={`${labelClass} ${inputErrors.firstname ? 'border-red-500' : 'border-t-ccc'}`}>
            Keresztnév
            {inputErrors.firstname && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
            <input type="text"
              className="grow focus:outline-none font-normal"
              placeholder="Timely"
              value={firstname}
              onChange={e => setFirstname(e.target.value)}
            />
          </label>
          <div className='text-right'>
            <button className='btn bg-t-green btn-outline mt-4' onClick={updateProfile}
              disabled={loading || (lastname === originalLastname && firstname === originalFirstname)}
            >
              Mentés
            </button>
          </div>
        </div>
      </div>

      <div className="collapse bg-base-200 rounded-lg mb-2 border-2 border-t-dark">
        <input type="checkbox" defaultChecked disabled style={{ cursor: 'default'}}/>
        <div className="collapse-title text-xl font-medium bg-t-beige">Hírlevél</div>
        <div className="collapse-content">
          <label className="cursor-pointer label justify-start w-fit mt-8">
            <input type="checkbox" 
              className="checkbox border-t-dark bg-t-light mr-2"
              checked={newsletter} 
              onChange={e => setNewsletter(e.target.checked)}
            />
            <span className="label-text text-t-dark text-lg">Szeretnék hírlevelet kapni.</span>
          </label>

          <div className='text-right'>
            <button className='btn bg-t-green btn-outline mt-4' onClick={updateNewsletter} disabled={loading || (newsletter === originalNewsletter)}>Mentés</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminProfileEditor;
