import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies
import '../App.css';
import { useParams } from 'react-router-dom';
import AdminPage from './AdminPage'; // Import your AdminPage component
import AdminLogin from './AdminLogin'; // Import your AdminLogin component

function Admin() {
  const [user, setUser] = useState(null); // State to store user info
  const [userFirstName, setUserFirstName] = useState(null);
  const { identifier } = useParams();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    // Get the uuid from the cookie
    const uuid = Cookies.get('uuid');
    const firstname = Cookies.get('firstname');
    
    if (uuid) {
      setUser({ uuid });
      setUserFirstName(firstname);

    } else {
      setUser(null);
    }
    setLoading(false);
  }, []);

  if(loading) {
    return (
      <>
        loading...
      </>
    );
  }
  
  return (
    <>
      {user ? (
        <AdminPage userId={user?.uuid} userFirstName={userFirstName} identifier={identifier} />
      ) : (
        <AdminLogin identifier={identifier} />
      )}
    </>
  );
}

export default Admin;
