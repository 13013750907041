import React, { useState, useEffect } from 'react';
import '../App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import apiClient from '../helper/apiClient';

function Register() {

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const { search } = useLocation();
    const navigate = useNavigate();

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [newsletter, setNewsletter] = useState(false);
    const [active, setActive] = useState(false);
    const [identifier, setIdentifier] = useState('');
    const [inputErrors, setInputErrors] = useState(
        {
            lastName: false,
            firstName: false,
            email: false,
            identifier: false
        }
    );

    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [passwordCriteria, setPasswordCriteria] = useState(
        {
            length: false,
            uppercase: false,
            number: false,
            specialChar: false
        }
    );

    const labelClass = "input input-bordered rounded-xl border-2 flex items-center gap-2 border-t-dark"
    const divClass = "container my-4 lg:w-3/6 md:w-4/6 sm:w-5/6 bg-gradient-to-tl from-t-light/20 via-t-light/60 to-t-light/20 px-10 py-10 rounded-lg"


    useEffect(() => {
        const params = new URLSearchParams(search);
        const id = params?.get('id');
        if (id) {
            if(id.length < 3) {
                toast.error('Az azonosítónak legalább 3 karakterből kell állnia.');
                setInputErrors(prevState => ({
                    ...prevState,
                    identifier: true,
                }));
            } else {
                setIdentifier(id);
            }
        }
    }, [search]);


    const isError = async () => {
        let hasError = false;

        if (!lastname) {
            if(!hasError) {
                toast.error('Meg kell adnod a vezetéknevedet.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                lastName: true,
            }));
            hasError = true;
        }

        if (!firstname) {
            if(!hasError) {
                toast.error('Meg kell adnod a keresztnevedet.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                firstName: true,
            }));
            hasError = true;
        }

        if (!identifier) {
            if(!hasError) {
                toast.error('Meg kell adnod egy Timely azonosítót.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                identifier: true,
            }));
            hasError = true;
        }

        if (identifier.length < 3) {
            if(!hasError) {
                toast.error('Az azonosítónak legalább 3 karakterből kell állnia.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                identifier: true,
            }));
            hasError = true;
        }

        if (!await isIdentifierAvailable(identifier)) {
            if(!hasError) {
                toast.error('Ez az azonosító nem elérhető.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                identifier: true,
            }));
            hasError = true;
        }

        if (!email) {
            if(!hasError) {
                toast.error('Meg kell adnod az e-mail címed.');
            }
            setInputErrors(prevState => ({
                ...prevState,
                email: true,
            }));
            hasError = true;
        }

        if(!isPasswordMatch) {
            if(!hasError) {
                toast.error('A két jelszónak egyeznie kell.');
            }
            hasError = true;
        }

        if(!passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) {
            if(!hasError) {
                toast.error('A jelszónak biztonságosnak kell lennie.');
            }
            hasError = true;
        }

        return hasError;
    };
    

    // const handleRegistration = async (e) => {
    //     e.preventDefault();
    //     setInputErrors({
    //         lastName: false,
    //         firstName: false,
    //         email: false,
    //         identifier: false
    //     });
    //     setLoading(true);

    //     if(await isError()) {
    //         scrollToTop();
    //         setLoading(false);
    //         return;
    //     }

    //     const userData = {
    //         firstname,
    //         lastname,
    //         email,
    //         password,
    //         newsletter,
    //         active,
    //         identifier
    //     };

    //     fetch(`${apiBaseUrl}api/register`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
    //         },
    //         body: JSON.stringify(userData),
    //     })
    //     .then(response => {
    //         if (response.status === 409) {
    //             toast.error('Ez az email cím már foglalt.');
    //             setLoading(false);
    //             return;
    //         }
    //         return response.json();
    //     })
    //     .then(data => {
    //         if (data) {
    //             toast.success('Sikeres regisztráció.');
    //             navigate('/success');
    //             // console.log('Register data:', data);
    //         }
    //         setLoading(false);
    //     })
    //     .catch(error => {
    //         console.error('Error:', error);
    //         setLoading(false);
    //     });
    // }

const handleRegistration = async (e) => {
    e.preventDefault();
    setInputErrors({
        lastName: false,
        firstName: false,
        email: false,
        identifier: false
    });
    setLoading(true);

    if (await isError()) {
        scrollToTop();
        setLoading(false);
        return;
    }

    const userData = {
        firstname,
        lastname,
        email,
        password,
        newsletter,
        active,
        identifier
    };

    try {
        const response = await apiClient.post(`/api/register`, userData);

        // Handle successful registration response
        if (response.data) {
            toast.success('Sikeres regisztráció.');
            navigate('/success');
        }
    } catch (error) {
        // Handle errors specifically
        if (error.response) {
            // Handle specific HTTP status codes
            if (error.response.status === 409) {
                toast.error('Ez az email cím már foglalt.');
            } else {
                toast.error('Hiba történt a regisztráció során.');
            }
        } else if (error.code === 'ECONNABORTED') {
            toast.error('A szerver nem válaszolt időben.');
        } else {
            console.error('Error:', error);
        }
    } finally {
        setLoading(false);  // Ensure loading is set to false regardless of success or error
    }
};


    // const isIdentifierAvailable = async (identifier) => {
    //     return fetch(`${apiBaseUrl}api/check/identifier?identifier=${identifier}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
    //         }
    //     })
    //     .then((response) => response.json())
    //     .then((data) => {
    //         // console.log('Identifier data:', data);
    //         return data?.available ?? false;
    //     })
    //     .catch((error) => {
    //         console.error('Error checking identifier:', error);
    //         return false;
    //     });
    // };
    
    const isIdentifierAvailable = async (identifier) => {
        try {
            const response = await apiClient.get(`/api/check/identifier`, {
                params: { identifier }, // Use params to construct the query string
            });
    
            // Return the availability status from the response data
            return response.data?.available ?? false; 
        } catch (error) {
            console.error('Error checking identifier:', error);
            return false; // Return false in case of error
        }
    };

    const handlePasswordChange = (value, isConfirmation = false) => {
        if (!isConfirmation) {
            setPassword(value);
        } else {
            setPasswordConfirmation(value);
        }
        setIsPasswordMatch(!isConfirmation ? value === passwordConfirmation : password === value);
        checkPasswordCriterias(value);
    };

    const checkPasswordCriterias = (value) => {
        const criterias = {
            length: value.length >= 9,
            uppercase: /[A-Z]/.test(value),
            number: /\d/.test(value),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
        }
        setPasswordCriteria(criterias)
    };

    const scrollToTop = () => {
        if(window !== undefined) {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // For smooth scrolling
            });
        }
    };
  
    return (
        <div className='register-page'>
        <div className={`${divClass}`}>
            <h1 className="text-center text-t-dark">
                Regisztráció
                {/* <span className='mark-timely text-t-dark'>Timely</span> */}
            </h1>
        </div>
        <div className={`${divClass}`}>
            <form onSubmit={handleRegistration} className="form-control gap-4">
                <h2 className='text-t-dark'>Személyes adatok</h2>
                <label className={`${labelClass} ${inputErrors.lastName ? 'border-red-500' : ''}`}>
                    Vezetéknév
                    {inputErrors.lastName && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
                    <input type="text" className="grow"
                        placeholder="Timely"
                        value={lastname}
                        onChange={e => setLastname(e.target.value)}
                    />
                </label>
                <label className={`${labelClass} ${inputErrors.firstName ? 'border-red-500' : ''}`}>
                    Keresztnév
                    {inputErrors.firstName && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
                    <input type="text" className="grow"
                        placeholder="Tim"
                        value={firstname} 
                        onChange={e => setFirstname(e.target.value)}
                    />
                </label>
                <hr className='border-t-dark border-1'/>
                <h2 className='text-t-dark'>Fiók adatok</h2>
                    <label className={`${labelClass} ${inputErrors.identifier ? 'border-red-500' : ''}`}>
                        Azonosító
                        {inputErrors.identifier && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
                        <input type="text" className="grow"
                            placeholder="Azonosító"
                            value={identifier}
                            onChange={e => setIdentifier(e.target.value)}
                            />
                    </label>
                <div className="criterias text-left mx-6">
                        <div className="criterias-title">Ez lesz az egyedi URL-ed, amit meg tudsz osztani az ügyfeleiddel.</div>
                        <div className="criterias-title">Így {identifier ? 'fog kinézni az URL-ed' : 'néz ki egy példa URL'}: <span className='font-bold'>https://timely.hu/{identifier ? identifier : 'beautysalon'}</span></div>
                    </div>
                <label className={`${labelClass} ${inputErrors.email ? 'border-red-500' : ''}`}>
                    E-mail cím
                    {inputErrors.email && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
                    <input type="email" className="grow"
                        placeholder="hello@timely.hu"
                        value={email} 
                        onChange={e => setEmail(e.target.value)} 
                    />
                </label>
                <label className={`${labelClass} ${(!isPasswordMatch || !passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) ? 'border-red-500' : ''}`}>
                    Jelszó
                    {(!isPasswordMatch || !passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
                    <input type="password" className="grow"
                        placeholder="********"
                        value={password} 
                        onFocus={(e) => {
                            handlePasswordChange(e.target.value);
                        }}
                        onBlur={(e) => {
                            handlePasswordChange(e.target.value);
                        }}
                        onChange={(e) => {
                            handlePasswordChange(e.target.value);
                        }}
                    />
                </label>
                <label className={`${labelClass} ${(!isPasswordMatch || !passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) ? 'border-red-500' : ''}`}>
                    Jelszó megerősítése
                    {(!isPasswordMatch || !passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) && <span className="indicator-item badge badge-sm bg-red-500 text-t-light">!</span>}
                    <input type="password" className="grow"
                        placeholder="********"
                        value={passwordConfirmation}
                        onFocus={(e) => {
                            handlePasswordChange(e.target.value, true);
                        }}
                        onBlur={(e) => {
                            handlePasswordChange(e.target.value, true);
                        }}
                        onChange={(e) => {
                            handlePasswordChange(e.target.value, true);
                        }}
                    />
                </label>
                {!loading && <>
                    {/* {(!passwordCriteria.length || !passwordCriteria.uppercase || !passwordCriteria.number || !passwordCriteria.specialChar) && */}
                    <div className="criterias text-left mx-6">
                        <div className="criterias-title">Az jelszavadnak tartalmaznia kell:</div>
                        <ul>
                            {<li className={!passwordCriteria.length ? `list-disc` : `list-image-checkmark`}>legalább 9 karaktert</li>}
                            {<li className={!passwordCriteria.uppercase ? `list-disc` : `list-image-checkmark`}>kis- és nagybetűt</li>}
                            {<li className={(!passwordCriteria.number || !passwordCriteria.specialChar) ? `list-disc` : `list-image-checkmark`}>minimum egy számot és egy speciális karaktert</li>}
                        </ul>
                    </div>
                    {/* } */}
                </>}
                <hr className='border-t-dark border-1'/>
                <h2 className='text-t-dark'>Egyéb adatok</h2>
                <label className="cursor-pointer label justify-start w-fit">
                    <input type="checkbox" 
                            checked={newsletter} 
                            onChange={e => setNewsletter(e.target.checked)} className="checkbox border-t-dark bg-t-light mr-2" />
                    <span className="label-text text-t-dark text-lg">Hírlevél feliratkozás</span>
                </label>
                {loading ?
                    <button className="btn bg-t-green">
                        <span className="loading loading-spinner"></span>
                    </button>
                :  
                    <button type="submit" className="btn bg-t-green hover:bg-t-pink text-lg text-t-dark">Register</button>
                }
            </form>
        </div>
        </div>
    );
}

export default Register;
