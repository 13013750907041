import React, {useEffect, useState} from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';

function Home() {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [message, setMessage] = useState('Default');
  const divClass = "container my-4 w-5/6 px-10 py-10"
  const navigate = useNavigate();

  // useEffect(() => {
  //   fetch(`${apiBaseUrl}api/hello`)
  //     .then(response => response.json())
  //     .then(data => {
  //       setMessage(data.message);
  //     })
  //     .catch(error => console.error('Error:', error));
  // }, []);
  
// "Alakítsd ki saját időpontfoglaló rendszeredet – regisztrálj, és kezdd el foglalásaidat egyszerűen kezelni!"

// Szeretnéd egyszerűen és profin intézni az időpontfoglalásokat? Regisztrálj nálunk, és az előfizetéssel saját foglalórendszert kapsz, amely minden igényedet kielégíti! Kövesd nyomon a foglalásokat, kommunikálj ügyfeleiddel, és küldj emlékeztetőket pillanatok alatt.

// Csatlakozz hozzánk, és vedd kézbe az irányítást – legyen saját, megbízható időpontfoglaló rendszered, amellyel még hatékonyabbá teheted a vállalkozásodat!

  return (
    <div className='home-page'>
      <div className="navbar mb-10 sticky">
          <div className="navbar-start">
              <div className="logo cursor-pointer lg:text-3xl lg:absolute relative flex flex-row items-end justify-center text-t-dark text-5xl font-bold" onClick={() => navigate('/')}>
              {/* <div className="cursor-pointer text-5xl flex items-end font-bold text-t-dark" onClick={() => navigate('/')}> */}
                  <img className='w-20 h-20' src='../timely_logo.svg' />
                  Timely
              </div>
          </div>
          <div className="navbar-center hidden lg:flex">
          </div>
          <div className="navbar-end">
              <a className="btn bg-t-green hover:bg-t-pink border-0 text-lg fixed cursor-pointer" onClick={() => navigate('/register')}>Regisztráció</a>
          </div>
      </div>
      <h1 className="mt-8 mb-4 text-center text-4xl font-extrabold leading-none tracking-tight text-t-dark md:text-5xl lg:text-6xl">Készítsd el <span className="underline underline-offset-3 decoration-8 decoration-t-light">saját időpontfoglaló</span> rendszeredet</h1>
      <h2 className="text-3xl font-normal text-t-dark lg:text-3xl">Regisztrálj, és kezdd el foglalásaidat egyszerűen kezelni!</h2>
      
      <div className={`${divClass}`}>
        <p className="text-2xl font-normal text-t-dark">Szeretnéd egyszerűen és profin intézni az időpontfoglalásokat? Regisztrálj és saját foglalórendszert kapsz, amely minden igényedet kielégíti! Kövesd nyomon a foglalásokat, kommunikálj ügyfeleiddel.</p>
      </div>

      <h1 className="mt-8 mb-4 text-center text-3xl font-extrabold leading-none tracking-tight text-t-dark md:text-3xl lg:text-4xl">30 napig <span className="underline underline-offset-3 decoration-8 decoration-t-light">ingyenesen</span> kipróbálhatod!</h1>

      <div className={`${divClass}`}>
        {/* <p className="text-2xl font-normal text-t-dark">Szeretnéd egyszerűen és profin intézni az időpontfoglalásokat? Regisztrálj nálunk, és az előfizetéssel saját foglalórendszert kapsz, amely minden igényedet kielégíti! Kövesd nyomon a foglalásokat, kommunikálj ügyfeleiddel, és küldj emlékeztetőket pillanatok alatt.</p> */}
        <p className="text-2xl font-normal text-t-dark">Csatlakozz hozzánk és legyen saját, megbízható időpontfoglaló rendszered, amellyel még hatékonyabbá teheted a vállalkozásodat!</p>
      </div>

      <div className="w-full flex justify-center gap-4 mt-8">
        <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
          <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">Éves előfizetés</h5>
          <div className="flex items-baseline text-gray-900 dark:text-white">
          <span className="text-5xl font-extrabold tracking-tight">31 950</span>
          <span className="text-3xl font-semibold">Ft</span>
          <span className="ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">/év</span>
          </div>
          <ul role="list" className="space-y-5 my-7">
            <li className="flex items-center">
              <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">3 hónap ingyen</span>
            </li>
            <li className="flex">
              <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">saját Timely url</span>
            </li>
            <li className="flex">
              <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Személyre szabható időpontfoglaló</span>
            </li>
            <li className="flex line-through decoration-gray-500">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Sketch Files</span>
            </li>
            <li className="flex line-through decoration-gray-500">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 ms-3">API Access</span>
            </li>
            <li className="flex line-through decoration-gray-500">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Complete documentation</span>
            </li>
            <li className="flex line-through decoration-gray-500">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 ms-3">24×7 phone & email support</span>
            </li>
          </ul>
          <button type="button" className="btn w-full bg-t-green hover:bg-t-pink text-lg text-t-dark">Kiválaszt</button>
        </div>


        <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
          <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">Havi előfizetés</h5>
          <div className="flex items-baseline text-gray-900 dark:text-white">
            <span className="text-5xl font-extrabold tracking-tight">3 550</span>
            <span className="text-3xl font-semibold">Ft</span>
            <span className="ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">/hó</span>
          </div>
          <ul role="list" className="space-y-5 my-7">
            <li className="flex items-center">
              <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">2 team members</span>
            </li>
            <li className="flex">
              <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">20GB Cloud storage</span>
            </li>
            <li className="flex">
              <svg className="flex-shrink-0 w-4 h-4 text-blue-700 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ms-3">Integration help</span>
            </li>
            <li className="flex line-through decoration-gray-500">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Sketch Files</span>
            </li>
            <li className="flex line-through decoration-gray-500">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 ms-3">API Access</span>
            </li>
            <li className="flex line-through decoration-gray-500">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Complete documentation</span>
            </li>
            <li className="flex line-through decoration-gray-500">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 ms-3">24×7 phone & email support</span>
            </li>
          </ul>
          <button type="button" className="btn w-full bg-t-green hover:bg-t-pink text-lg text-t-dark">Kiválaszt</button>
        </div>

</div>

    </div>
  )
}

export default Home;
