import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies
import { useLocation, useNavigate } from 'react-router-dom';

const AdminMenu = ({ setPage }) => {

    const { search } = useLocation();
    const navigate = useNavigate();

    const handlePageChange = (newPage) => {
        setPage(newPage);
        const params = new URLSearchParams(search);
        params.set('p', newPage);
        navigate(`?${params.toString()}`, { replace: true });
    };

    const handleLogout = () => {
        // Remove the uuid cookie
        Cookies.remove('uuid');
        Cookies.remove('firstname');
        
        // Reload the page to trigger Admin.js to check login state
        window.location.reload();
    };
    
  return (
    <div className="dropdown dropdown-end">
        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle hover:bg-t-green">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
        </div>
        <ul tabIndex={0}
            className="menu menu-lg dropdown-content bg-base-100 rounded-box z-[2] mt-3 w-60 p-2 shadow">
            {/* <li>
                <a className='hover:bg-t-green active:bg-t-green' onClick={() => handlePageChange('personal')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    Személyes
                </a>
            </li> */}
            <li>
                <a className='hover:bg-t-green !active:bg-t-green' onClick={() => handlePageChange('account')}>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21.5 12H16c-.7 2-2 3-4 3s-3.3-1-4-3H2.5"/><path d="M5.5 5.1L2 12v6c0 1.1.9 2 2 2h16a2 2 0 002-2v-6l-3.4-6.9A2 2 0 0016.8 4H7.2a2 2 0 00-1.8 1.1z"/></svg> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                    Profilom
                </a>
            </li>
            <li>
                <a className='hover:bg-t-green active:bg-t-green' onClick={() => handlePageChange('calendar')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    Naptáram
                </a>
            </li>
            <li>
                <a className='hover:bg-t-green active:bg-t-green' onClick={() => handlePageChange('introduction')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                    Bemutatkozás
                </a>
            </li>
            <li>
                <a className='hover:bg-t-green active:bg-t-green' onClick={() => handlePageChange('services')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                    Szolgáltatásaim
                </a>
            </li>
            <li>
                <a className='hover:bg-t-green active:bg-t-green' onClick={() => handlePageChange('workplan')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                    Munkaidőm
                </a>
            </li>
            {/* <li>
                <a className='hover:bg-t-green active:bg-t-green' onClick={() => navigate('/', '_blank')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9"/><path d="M9 22V12h6v10M2 10.6L12 2l10 8.6"/></svg>
                    Oldalam
                </a>
            </li> */}
            <li>
                <a className='hover:bg-t-pink active:bg-t-pink' onClick={handleLogout}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M10 3H6a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h4M16 17l5-5-5-5M19.8 12H9"/></svg>
                    Kijelentkezem
                </a>
            </li>
        </ul>
    </div>
  )
}

export default AdminMenu