import React, { useState, useEffect } from "react";
import '../App.css';
import FieldEditor from '../components/editor/FieldEditor';
import ProfileImageUploader from '../components/editor/ProfileImageUploader';
import ServiceEditor from '../components/editor/ServiceEditor';
import DateEditor from '../components/editor/DateEditor';
import { useLocation } from 'react-router-dom';
import ProfileEditor from "../components/editor/ProfileEditor";
import EmailEditor from "../components/editor/EmailEditor";
import Calendar from '../components/Calendar';
import NavBar from "../components/NavBar";

function AdminPage({ userId, userFirstName, identifier }) {

    const { search } = useLocation();

    const [page, setPage] = useState("account");

    useEffect(() => {
        const params = new URLSearchParams(search);
        const urlPage = params.get('p') || 'account';
        setPage(urlPage);
    }, [search]);

    return (
        <div className='admin-page'>
            <NavBar userFirstName={userFirstName} setPage={setPage} />
            <div className='px-4'>
                {page === "account" &&
                <>
                    <ProfileEditor userId={userId} identifier={identifier}/>
                    <EmailEditor userId={userId} identifier={identifier}/>
                </>
                }
                {page === "calendar" &&
                    <>
                        <Calendar userId={userId} identifier={identifier}/>
                    </>
                }
                {page === "introduction" &&
                <>
                    <ProfileImageUploader userId={userId} identifier={identifier}/>
                    <FieldEditor type='title' userId={userId} identifier={identifier}/>
                    <FieldEditor type='introduction' userId={userId} identifier={identifier}/>
                    <FieldEditor type='email' userId={userId} identifier={identifier}/>
                    <FieldEditor type='phone' userId={userId} identifier={identifier}/>
                </>
                }
                {page === "services" &&
                <>
                    <ServiceEditor type='service' userId={userId} identifier={identifier}/>
                </>
                }
                {page === "workplan" &&
                <>
                    <DateEditor userId={userId} identifier={identifier}/>
                </>
                }

            </div>
        </div>
    );
}

export default AdminPage;
