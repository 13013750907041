import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { convertTimeToMinutes } from "../../helper/timeConverters";
// import apiClient from '../../helper/apiClient';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillCustomFormats, quillCustomModules } from '../../helper/quillOptions';


export default function ServiceBlock({ service, removeService, updateService, identifier, userId }) {
  
  const [description, setDescription] = useState(service?.description);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (service) {
      const defaultDays = {
        H: false,
        K: false,
        Sze: false,
        Cs: false,
        P: false,
        Szo: false,
        V: false,
      };
      
      let parsedDays;
      try {
        parsedDays = service?.repetitive_days ? JSON.parse(service.repetitive_days) : defaultDays;

      } catch (error) {
        console.error("Error parsing repetitive_days:", error);
        parsedDays = defaultDays;
      }
      setRepetitiveDays(parsedDays)
      setLoading(false);
    }
  }, []);

  const [label, setLabel] = useState(service?.label || '');
  // const [isLabelDisabled, setIsLabelDisabled] = useState(true);
  // const inputRef = useRef(null);

  // useEffect(() => {
  //     if (!isLabelDisabled) {
  //         inputRef.current.focus(); // Focus the input when it's enabled
  //     }
  // }, [isLabelDisabled]);

  const [hour, setHour] = useState(service?.hour);
  const [minute, setMinute] = useState(service?.minute);

  const [dateType, setDateType] = useState(service?.date_type);

  const [repetitiveDays, setRepetitiveDays] = useState({
    H: false,
    K: false,
    Sze: false,
    Cs: false,
    P: false,
    Szo: false,
    V: false,
  });

  // const [repetitiveDays, setRepetitiveDays] = useState(JSON.parse(service?.repetitive_days) || {
  //   H: false,
  //   K: false,
  //   Sze: false,
  //   Cs: false,
  //   P: false,
  //   Szo: false,
  //   V: false,
  // });

  const [headcount, setHeadcount] = useState(service?.headcount);

  const [startHour, setStartHour] = useState(service?.start_hour);
  const [startMinute, setStartMinute] = useState(service?.start_minute);

  const [selectedImage, setSelectedImage] = useState('');
  const [imageUrl, setImageUrl] = useState(service?.image);
  const [isSavedImage, setIsSavedImage] = useState(service?.image ? true : false);

  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [imageDeleteConfirm, setImageDeleteConfirm] = useState(false);
  const [serviceDeleteConfirm, setServiceDeleteConfirm] = useState(false);


  const handleSave = () => {
    updateService(service.id, { ...service, label, description, headcount, image: imageUrl, hour, minute, date_type: dateType, repetitive_days: JSON.stringify(repetitiveDays), start_hour: startHour, start_minute: startMinute });
  }

  useEffect(() => {
    if(selectedImage) {
        handleImageSave()
    }
  }, [selectedImage]);

  const handleRepetitiveDaysChange = (event) => {
    const { name, checked } = event.target;
    setRepetitiveDays((prevDays) => ({
      ...prevDays,
      [name]: checked,
    }));
  };

  const getRepetitiveDayLabel = (dayCode) => {
    const days = {
      H: "H",
      K: "K",
      Sze: "Sze",
      Cs: "Cs",
      P: "P",
      Szo: "Szo",
      V: "V",
    };
    return days[dayCode];
  };

  const handleImageSave = async () => {

    setUploading(true);

    if (!selectedImage) {
      setUploading(false);
      return;
    }

    if (!selectedImage.type?.startsWith('image/')) {
      setSelectedImage('');
      toast.error('Kizárólag képet tölthetsz fel.');
      setUploading(false);
      return;
    }

    if(selectedImage.type !== "image/png" && selectedImage.type !== "image/jpeg" && selectedImage.type !== "image/gif") {
      setSelectedImage('');
      toast.warning('Nem támogatott képformátum.');
      setUploading(false);
      return;
    }

    if (!(selectedImage.size <= 1000000)) {
      setSelectedImage('');
      toast.warning('A kép nem lehet nagyobb, mint 1 MB.');
      setUploading(false);
      return;
    }

    const fileExtension = selectedImage.name.split('.').pop();
    const newFileName = `${identifier}-${service.id}-s.${fileExtension}`;
    const renamedImage = new File([selectedImage], newFileName, { type: selectedImage.type });

    const formData = new FormData();
    formData.append('file', renamedImage);
    formData.append('userId', userId);
    formData.append('serviceId', service?.id);
    formData.append('identifier', identifier);

    try {
      const response = await fetch(`${apiBaseUrl}api/service-image/upload`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
        },
        body: formData,
      });

      if (response.ok) {
        // toast.success('Sikeres mentés.');
        setSelectedImage('');
        setIsSavedImage(true);
        setImageUrl('uploads/services/'+newFileName)
        handleSave();
      } else {
        toast.error('Hiba történt.');
      }
    } catch (error) {
      toast.error('Hiba történt.');
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}api/service-image/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`
        },
        body: JSON.stringify({ serviceId: service?.id, userId, identifier }),
      });

      if (response.ok) {
        setImageDeleteConfirm(false)
        setIsSavedImage(false);
        setImageUrl('');
        setSelectedImage('');
        toast.success('Kép törlése sikeres.');
      } else {
        toast.error('Nem sikerült törölni a képet.');
      }
    } catch (error) {
      toast.error('Hiba történt a törlés során.');
    }
  };

  // Helper function to handle input changes
  const handleDigitInput = (e, setter, max) => {
    const value = e.target.value.replace(/\D/, ''); // Remove non-digit characters
    if (value.length <= 2 && Number(value) <= max) { // Allow max 2 digits and within range
      // Ensure 0 is correctly interpreted
      setter(value === '' ? '' : Number(value));
    }
  };

  const handleDeleteService = () => {
    handleDeleteImage();
    removeService(service.id);
  };

  if(loading) {
    return (
      <div className='flex justify-center min-h-50-screen'>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  return (
    <>
        <div className="card card-compact bg-base-100 shadow-xl">
          <figure>
            {isSavedImage || selectedImage ? (
              <div
                className="mb-4 my-0 mx-auto w-full h-40 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${isSavedImage ? apiBaseUrl + imageUrl : URL.createObjectURL(selectedImage)})`,
                }}
              >
                <div className='flex justify-end items-start h-40 p-2'>
                  {imageDeleteConfirm ? (
                    <>
                      <button
                        className="btn bg-t-green btn-outline hover:bg-t-green min-h-8 h-8 px-1 mr-2"
                        onClick={() => setImageDeleteConfirm(false)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M11 17l-5-5 5-5M18 17l-5-5 5-5"/></svg>
                      </button>
                      <button
                        className="btn bg-t-pink btn-outline hover:bg-t-sec min-h-8 h-8 px-1"
                        onClick={handleDeleteImage}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                      </button>
                    </>
                  ) : isSavedImage && (
                    <button
                      className="btn bg-t-pink btn-outline hover:bg-t-sec min-h-8 h-8 px-1"
                      onClick={() => setImageDeleteConfirm(true)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <label
                htmlFor={`service-image-${service?.id}`}
                className="flex flex-col items-center justify-center w-full h-40 border-2 border-t-gray border-dashed rounded-2xl rounded-b-none cursor-pointer bg-t-light hover:bg-t-light"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-t-dark"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-t-dark">
                    <span className="font-semibold">Kattints a feltöltéshez</span> vagy húzd ide a fájlt
                  </p>
                  <p className="text-xs text-t-dark">PNG, JPG vagy GIF (MAX. 1MB)</p>
                </div>
                <input
                  id={`service-image-${service?.id}`}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) setSelectedImage(file);
                  }}
                />
              </label>
            )}
          </figure>

        <div className="card-body">
            <div className='flex'>
                <input
                    className='card-title w-full focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
                    placeholder="Szolgáltatásod neve"
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                />
            </div>

            <hr />
            <div>Leírás</div>
            <ReactQuill 
              className='bg-t-light'
              modules={quillCustomModules}
              formats={quillCustomFormats}
              theme="snow"
              value={description}
              onChange={setDescription}
            />

            <hr className='mt-14'/>
            <div>Időtartam</div>

            <div className="join w-full justify-center rounded-none">
              <select
                  className="select w-1/2 focus:outline-none rounded-none join-item border border-t-ccc bg-t-light"
                  value={hour}
                  onChange={(e) => setHour(Number(e.target.value))}
              >
                  <option disabled value={10}>Időtartam</option>
                  <option value={0}>0 óra</option>
                  <option value={1}>1 óra</option>
                  <option value={2}>2 óra</option>
                  <option value={3}>3 óra</option>
                  <option value={4}>4 óra</option>
              </select>
              <select
                  className="select w-1/2 focus:outline-none rounded-none join-item border border-t-ccc bg-t-light"
                  value={minute}
                  onChange={(e) => setMinute(Number(e.target.value))}
              >
                  <option disabled value={10}>Időtartam</option>
                  <option value={0}>0 perc</option>
                  <option value={15}>15 perc</option>
                  <option value={30}>30 perc</option>
                  <option value={45}>45 perc</option>
              </select>
            </div>

            <hr />
            <div>Létszám</div>

            <input
              type='text'
              className='w-full focus:outline-none font-medium px-4 py-3 border border-t-ccc bg-t-light flex items-center gap-2'
              placeholder="Létszám"
              value={headcount}
              onChange={(e) => handleDigitInput(e, setHeadcount, 100)}
            />
            
          {/* <label className="cursor-pointer label justify-start w-fit mt-8">
            <input type="checkbox" 
              className="checkbox border-t-dark bg-t-light mr-2"
              // checked={fixDate} 
              // onChange={e => setFixDate(e.target.checked)}
            />
            <span className="label-text text-t-dark text-lg">Fix dátum megadása.</span>
          </label> */}

            <hr />
            <div>Típus</div>

            <div className="w-full justify-center rounded-none">
              <select
                className="select w-full focus:outline-none rounded-none border border-t-ccc bg-t-light"
                value={dateType || "type"}
                onChange={(e) => setDateType(e.target.value)}
              >
                <option value="type">Típus</option>
                <option value="onetime">Egyszeri</option>
                <option value="repetitive">Ismétlődő</option>
                {/* <option value="free">Szabad dátum</option> */}
              </select>
            </div>


          {dateType === "repetitive" &&
          <>
            <div className="w-full justify-center rounded-none">
            <div className='my-2'>Milyen napokon lesz?</div>
              <ul className="grid w-full gap-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
                {Object.entries(repetitiveDays).map(([dayCode, checked]) => (
                    <li key={`${service.id}-${dayCode}`}>
                        <input type="checkbox" id={`${service.id}-${dayCode}`} name={dayCode}
                          checked={checked}
                          onChange={handleRepetitiveDaysChange} className="hidden peer"/>
                        <label htmlFor={`${service.id}-${dayCode}`} className="inline-flex items-center justify-center w-full p-2 text-t-dark bg-t-gray border-2 border-t-ccc rounded-lg cursor-pointer peer-checked:border-t-dark peer-checked:bg-t-blue peer-checked:text-t-dark hover:text-t-light hover:bg-t-dark">                           
                            <div className="block">
                                {getRepetitiveDayLabel(dayCode)}
                            </div>
                        </label>
                    </li>
                ))}
                </ul>
            </div>
            <div className="flex w-full mb-4 bg-white">
              <div>
                <div className='my-2'>Mikor kezdődik?</div>
                <div className="join rounded-none relative flex items-center w-1/2 border border-t-ccc">
                  <input
                      // value={startHour.toString().padStart(2, '0')}
                      // value={startHour !== null ? startHour?.toString().padStart(2, '0') : ''}
                      value={startHour || ''}
                      onChange={(e) => handleDigitInput(e, setStartHour, 23)}
                      length="2"
                      min="0"
                      max="23" type="text" data-input-counter
                      className="text-right focus:outline-none w-full px-2 py-3 bg-t-light"
                      placeholder="00" required
                      // onInput={(e) => e.target.value = e.target.value.slice(0, 2)} // Limit to 2 digits
                  />
                  <span>:</span>
                  <input
                      // value={startMinute.toString().padStart(2, '0')}
                      value={startMinute || ''}
                      onChange={(e) => handleDigitInput(e, setStartMinute, 59)}
                      length="2"
                      min="0"
                      max="59" type="text" data-input-counter
                      className="focus:outline-none w-full p-2 bg-t-light"
                      placeholder="00" required
                      // onInput={(e) => e.target.value = e.target.value.slice(0, 2)} // Limit to 2 digits
                  />
                </div>
              </div>
            </div>
          </>
          }

          {dateType === 'onetime' &&
          <>
            <div className="flex w-full mb-4 bg-white">
              <div>
                <div className='my-2'>Milyen nap lesz?</div>
              </div>
            </div>
            <div className="flex w-full mb-4 bg-white">
              <div>
                <div className='my-2'>Mikor kezdődik?</div>
                <div className="join rounded-none relative flex items-center w-1/2 border border-t-ccc">
                  <input
                      // value={startHour.toString().padStart(2, '0')}
                      // value={startHour !== null ? startHour?.toString().padStart(2, '0') : ''}
                      value={startHour || ''}
                      onChange={(e) => handleDigitInput(e, setStartHour, 23)}
                      length="2"
                      min="0"
                      max="23" type="text" data-input-counter
                      className="text-right focus:outline-none w-full px-2 py-3 bg-t-light"
                      placeholder="00" required
                      // onInput={(e) => e.target.value = e.target.value.slice(0, 2)} // Limit to 2 digits
                  />
                  <span>:</span>
                  <input
                      // value={startMinute.toString().padStart(2, '0')}
                      value={startMinute || ''}
                      onChange={(e) => handleDigitInput(e, setStartMinute, 59)}
                      length="2"
                      min="0"
                      max="59" type="text" data-input-counter
                      className="focus:outline-none w-full p-2 bg-t-light"
                      placeholder="00" required
                      // onInput={(e) => e.target.value = e.target.value.slice(0, 2)} // Limit to 2 digits
                  />
                </div>
              </div>
            </div>
          </>
          }

          {/* {dateType === "free" &&
            <div className="w-full justify-center rounded-none">

            </div>
          }           */}
          <hr />
          {/* <div className="flex w-full mb-4 bg-white">
            {serviceDeleteConfirm ? (
              <>
                  <button
                  className="btn bg-t-green btn-outline mt-4 mr-2"
                  onClick={() => setServiceDeleteConfirm(false)}
                  >
                  Mégse
                  </button>
                  <button
                  className="btn bg-t-pink btn-outline mt-4"
                  onClick={() => removeService(service.id)}
                  >
                  Végleges törlés
                  </button>
              </>
            ) : (
              <button
                  className="btn bg-t-pink btn-outline mt-4"
                  onClick={() => setServiceDeleteConfirm(true)}
              >
                  Szolgáltatás törlése
              </button>
            )}
          </div> */}
          


          <div className="card-actions justify-end h-16">

            <div className='absolute bottom-4 left-4'>
            {serviceDeleteConfirm ? (
              <>
                  <button
                  className="btn bg-t-green btn-outline mt-4 mr-2"
                  onClick={() => setServiceDeleteConfirm(false)}
                  >
                  Mégse
                  </button>
                  <button
                    className="btn bg-t-pink btn-outline mt-4"
                    onClick={handleDeleteService}
                  >
                    Végleges törlés
                  </button>
              </>
            ) : (
              <button
                  className="btn bg-t-pink btn-outline mt-4"
                  onClick={() => setServiceDeleteConfirm(true)}
              >
                  Szolgáltatás törlése
              </button>
            )}
            </div>
            <div className='absolute bottom-4 right-4'>
              <div>
                <button
                  className="btn bg-t-green btn-outline mt-4 mr-2"
                  onClick={() => handleSave()}
                >
                  Mentés
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
</>
  );
}
