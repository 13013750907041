export const quillCustomFormats = [
  // 'header',
  'align',
  'bold', 'italic', 'underline', 'strike',
  // 'blockquote',
  // 'color', 'background',
  'list', 'bullet'
  // , 'indent'
];

export const quillCustomModules = {
  toolbar: [
    // [{ 'header': [1, 2, 3, false] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline','strike'
      // , 'blockquote'
    ],
    // [{ 'color': [] }, { 'background': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}
      // , {'indent': '-1'}, {'indent': '+1'}
    ],
  ],
};