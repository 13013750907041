import React, { useState, useEffect } from 'react';
import apiClient from '../helper/apiClient';
import toMariaDBTimestamp from '../helper/toMariaDBTimestamp';

const Calendar = ({ userId, identifier }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const today = new Date();

    const [filteredServices, setFilteredServices] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userId && identifier) {
            getUserServices("services");
        }
    }, []);


    const getUserServices = async (type) => {
        setLoading(true);

        const fieldData = {
            type,
            userId,
            identifier
        };

        try {
            const response = await apiClient.post(`/api/services/select`, fieldData);

            if (response.status === 404) {
                // handleLogout();
                setLoading(false);
                return;
            }

            const data = response.data;
            if (data) {
                setFilteredServices(data);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    // Helper function to change the month and switch to the week containing the 1st
    const changeMonth = (offset) => {
        const newDate = new Date(currentDate);
        const newMonth = newDate.getMonth() + offset;

        // Handle year changes when the month overflows or underflows
        newDate.setMonth(newMonth);
        newDate.setDate(1); // Set to the first day of the month

        // Adjust to ensure the week includes the 1st
        setCurrentDate(newDate);
    };

    // Helper function to change the week
    const changeWeek = (offset) => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + offset * 7); // Move by 7 days (1 week)
        setCurrentDate(newDate);
    };

    // Function to jump to today
    const jumpToday = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Clear time to avoid issues with different timezones
        setCurrentDate(today);  // Set the calendar to today's date
    };
  
    // Calculate the start and end of the current week
    const getWeekDates = () => {
        const startOfWeek = new Date(currentDate);
    
        // Adjust the start of the week to be Monday (not Sunday)
        const day = startOfWeek.getDay();
        const diff = day === 0 ? -6 : 1 - day; // If Sunday (0), move back 6 days; otherwise, adjust to Monday
        startOfWeek.setDate(startOfWeek.getDate() + diff);
    
        const days = Array.from({ length: 7 }, (_, index) => {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + index);
        const shortDate = toMariaDBTimestamp(date);

        return {
            shortDate,
            date,
            day: date.getDate(),
            dayName: ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'][date.getDay()],
            isPast: date < today.setHours(0, 0, 0, 0),
            isToday: date.toDateString() === today.toDateString(),
        };
        });
    
        return days;
    };
  

    const daysArray = getWeekDates();

    // Check if today is in the current week's days
    const isTodayInWeek = daysArray.some(({ isToday }) => isToday);

    // Determine the unique months in the week
    const uniqueMonths = [...new Set(daysArray.map(({ date }) => date.getMonth()))];
    const monthNames = uniqueMonths.map((month) => {
        const monthName = new Date(currentDate.getFullYear(), month)
        .toLocaleString('hu-HU', { month: 'long' });
        return monthName.startsWith('szeptember') ? 'szept' : monthName.slice(0, 3);
    });

    const year = currentDate.getFullYear();

    // Get the first and last days of the week
    const firstDay = daysArray[0].date;
    const lastDay = daysArray[6].date;

    // Format the first and last days as strings
    const firstDayString = `${firstDay.getDate()}`;
    const lastDayString = `${lastDay.getDate()}.`;
  
    if(loading) {
        return (
        <div className='flex justify-center min-h-50-screen'>
            <span className="loading loading-spinner loading-lg"></span>
        </div>
        ) 
    }

    return (
        <>
            {!isTodayInWeek && <div className="shadow flex justify-center content-center p-2 fixed bottom-3 right-3 rounded-full bg-t-green border-2 border-t-dark">
                <button onClick={jumpToday}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="5"/><path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"/></svg>
                </button>
            </div>}

        <div className="flex items-center justify-center mb-2 p-3 bg-t-light border-2 border-t-dark rounded-lg">
            <button className="text-xl font-bold mr-4" onClick={() => changeMonth(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M15 18l-6-6 6-6" />
            </svg>
            </button>

            <div className="text-lg text-center font-semibold w-full sm:w-3/12 md:2/6 lg:4/6">
            {year} {monthNames.join('. - ')}.
            </div>

            <button className="text-xl font-bold ml-4" onClick={() => changeMonth(1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M9 18l6-6-6-6" />
            </svg>
            </button>
        </div>

        <div className="flex items-center justify-center mb-2 p-3 bg-t-light border-2 border-t-dark rounded-lg">
            <button className="text-xl font-bold mr-4" onClick={() => changeWeek(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M15 18l-6-6 6-6" />
            </svg>
            </button>

            {/* Display the first and last day of the current week */}
            <div className="text-lg text-center font-semibold w-full sm:w-1/6 md:2/6 lg:4/6">
            {firstDayString} - {lastDayString}
            </div>

            <button className="text-xl font-bold ml-4" onClick={() => changeWeek(1)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M9 18l6-6-6-6" />
            </svg>
            </button>
        </div>

        <div
            // ref={calendarRef}
            className="calendar-container"
            // style={{ transition: 'transform 0.3s ease-in-out' }}
        >
            <div className="grid border-2 border-t-dark rounded-lg">
                {daysArray.map(({ shortDate, day, dayName, isPast, isToday }, index) => (
                    <div
                        key={day}
                        className={`flex w-full max-h-fit items-center border-t-dark p-2
                            ${isToday ? 'bg-t-beige' : isPast ? 'bg-black/15 opacity-70' : 'odd:bg-t-light even:bg-t-bgd'} 
                            ${index === daysArray.length - 1 ? 'border-b-0 rounded-b-lg' : 'border-b-2'}
                            ${index === 0 ? 'border-t-0 rounded-t-lg' : ''}
                        `}
                    >        
                    <div className="w-2/12 border-r-2 content-center border-t-dark text-center leading-8">
                    <div className="font-bold">{dayName}</div>
                    <div>{day}.</div>
                    </div>
                    <div className="w-10/12 self-start pl-2 grid xl:grid-cols-5 xl:gap-4 md:grid-cols-3 md:gap-2 grid-cols-2 gap-1">
                        {filteredServices.map((service, serviceIndex) => {
                            if (service.date_type === 'repetitive') {
                                const repetitiveDays = JSON.parse(service.repetitive_days);

                                if (repetitiveDays[dayName]) {
                                    return (
                                        <div className='text-cut w-full p-1 bg-t-green rounded-md border-t-dark border' key={serviceIndex}>
                                            {service.label}
                                        </div>
                                    );
                                }
                            }
                            if(service.date_type === 'onetime') {
                                if(shortDate === service.date) {
                                    return (
                                        <div key={serviceIndex}>
                                            {service.label}
                                        </div>
                                    )
                                }
                            }
                            return null;
                        })}
                    </div>
                </div>
                ))}
            </div>
        </div>
    </>
    );
};

export default Calendar;
